<template>
  <div>
    <div>
      <v-row>
        <v-col cols="6" md="3" lg="2">
          <v-btn
            elevation="0"
            height="50"
            :color="itemInPocket('favorite') ? 'secondary' : 'primary'"
            block
            outlined
            @click="cartHandler('favorite')"
          >
            <span>{{ $t('wishlist') }}</span>
            <v-icon class="mx-1">
              {{
                itemInPocket('favorite') ? 'mdi-heart' : 'mdi-heart-outline'
              }}</v-icon
            >
          </v-btn>
        </v-col>

        <!-- <v-col cols="6" md="3" lg="2">
          <v-btn
            elevation="0"
            style="height:50px"
            :color="itemInPocket('cart') ? 'secondary' : 'primary'"
            @click="cartHandler('cart')"
            block
            outlined
          >
            <span>{{ $t('cart') }}</span>

            <v-icon class="mx-1">{{
              itemInPocket('cart') ? 'mdi-cart-remove' : 'mdi-cart-plus'
            }}</v-icon>
          </v-btn>
        </v-col> -->

        <v-col cols="6" md="3" lg="2">
          <!-- <ShareNetwork
            network="linkedin"
            url="https://news.vuejs.org/issues/180"
            title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            quote="The hot reload is so fast it\'s near instant. - Evan You"
            hashtags="vuejs,vite"
            tag="div"
          /> -->

          <v-btn
            elevation="0"
            height="50"
            color="primary"
            outlined
            block
            @click="shareDialog = true"
          >
            <span>{{ $t('share') }}</span>
            <v-icon class="mx-1">mdi-share</v-icon>
          </v-btn>
          <!-- </ShareNetwork> -->
        </v-col>

        <v-col cols="6" md="3" lg="2" v-if="attachment">
          <v-btn
            elevation="0"
            height="50"
            color="primary"
            outlined
            block
            :href="attachment"
            target="_blank"
          >
            <v-icon class="mx-1">mdi-attachment</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="6" md="3" lg="2">
          <v-btn
            @click="buyNowHandler"
            elevation="0"
            height="50"
            color="secondary"
            outlined
            block
            class="hidden-md-and-up"
            >{{ $t('buyNow') }}</v-btn
          >
        </v-col>

        <!-- <v-col cols="12" class="hidden-md-and-up">
          <v-btn
            elevation="0"
            height="50"
            color="primary"
            @click="showCouponView = true"
            block
            v-if="!showCouponView"
          >
            <span>{{ $t('applyCoupon') }}</span>
          </v-btn>
          <CouponView v-if="showCouponView" />
        </v-col> -->
      </v-row>
    </div>
    <Dialog
      v-if="shareDialog"
      :dialog="shareDialog"
      @closeDialog="shareDialog = false"
      :noActions="false"
    >
      <v-row class="py-6 ma-0" justify="center">
        <!-- : 'https://4talents.academy/#/' -->
        <ShareNetwork
          class="mx-3"
          v-for="platform in platforms"
          :key="platform"
          :network="platform"
          :url="
            `${
              window.location.origin !== 'http://localhost:8080'
                ? window.location.origin + '/#' + $route.fullPath
                : `${process.env.VUE_APP_DOMAIN}/#/`
            }`
          "
          :title="course[lang + 'title']"
          :description="course[lang + 'short_discription']"
          :quote="
            course[lang + 'short_discription'] &&
              course[lang + 'short_discription'].slice(0, 20)
          "
          hashtags="4talent,4talents"
          tag="div"
        >
          <v-btn icon>
            <v-icon class="share-icon" :color="platform"
              >mdi-{{ platform }}</v-icon
            >
          </v-btn>
        </ShareNetwork>
      </v-row>
    </Dialog>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex';
export default {
  props: [
    'itemInPocket',
    'cartHandler',
    'attachment',
    'buyNowHandler',
    'course',
    'lang',
  ],
  components: {
    // CouponView: () => import('./CouponView'),
    Dialog: () => import('@/components/Dialog'),
  },
  data() {
    return {
      showCouponView: false,
      shareDialog: false,
      platforms: ['facebook', 'twitter', 'whatsapp'],
      // actions: [
      //   {
      //     text: 'addToFavorite',
      //     icon: this.itemInPocket() ? 'mdi-heart' : 'mdi-heart-outline',
      //     onClick: "addToCart('favorite')",
      //   },
      //   {
      //     text: 'share',
      //     icon: 'mdi-share',
      //     onClick: 'shareCourse()',
      //   },
      //   // {
      //   //   text: 'giftCourse',
      //   //   onClick: 'giftCourse()',
      //   // },
      //   // {
      //   //   text: 'applyCoupon',
      //   // },
      // ],
    };
  },
  methods: {
    // ...mapActions(['addToCartAction']),
  },
  mounted() {},
};
</script>

<style>
.share-icon {
  font-size: 40px !important;
}
</style>
